import { template as template_efc8938a8bdf47098e8166ca133fbb42 } from "@ember/template-compiler";
const FKLabel = template_efc8938a8bdf47098e8166ca133fbb42(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
